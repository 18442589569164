//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
export default {
  name: 'InviteStatistics',
  mixins: [Base],
  props: {
    statistics: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  }
};