//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;
import { preFetchPage } from '@/helpers/router';
import Base from '@/mixins/Base';
import BannerInvite from '@/components/Banners/Funrise/BannerInvite';
import InviteStatistics from '@/components/Invite/InviteStatistics';
import ReferralTable from '@/components/Invite/ReferralTable';
import ReferralCodeBox from '@/components/Invite/ReferralCodeBox';
export default {
  name: 'InviteFriends',
  mixins: [Base],
  components: {
    'app-banner-invite': BannerInvite,
    'app-invite-statistics': InviteStatistics,
    'app-referral-table': ReferralTable,
    'app-referral-code-box': ReferralCodeBox
  },
  preFetch: preFetchPage,
  meta: function meta() {
    return {
      title: 'PiasterCreek'
    };
  },
  data: function data() {
    return {
      isPromoCode: false
    };
  },
  computed: {
    referralData: function referralData() {
      return this.$store.getters['referral/referralPageData'];
    },
    referralBanner: function referralBanner() {
      return this.$store.getters['referral/referralPageBanner'];
    },
    referralHowWorks: function referralHowWorks() {
      return this.$store.getters['referral/referralHowWorks'];
    }
  },
  methods: {
    copyPromocode: function copyPromocode(promocode) {
      var _this = this;

      this.isPromoCode = true;
      copyToClipboard(promocode).then(function () {
        _this.callClipboardNote();

        console.log('copy promocode success');
      }).catch(function () {
        console.log('copy promocode error');
      });
    },
    copyReferralLink: function copyReferralLink(linkUrl) {
      var _this2 = this;

      this.isPromoCode = false;
      copyToClipboard(linkUrl).then(function () {
        _this2.callClipboardNote();
      }).catch(function () {
        console.log('copyToClipboard error');
      });
    },
    callClipboardNote: function callClipboardNote() {
      var notifyPosition = 'bottom';
      this.$q.notify({
        message: this.isPromoCode ? 'Promo code copied.' : 'Link copied.',
        icon: 'img:statics/referral/invite-notify.svg',
        color: 'success',
        position: notifyPosition,
        timeout: 5000,
        classes: 'notificaton-box notificaton-box--referral'
      });
    },
    callQualifiedNote: function callQualifiedNote() {
      var notifyPosition = 'top';
      this.$q.notify({
        message: this.isPromoCode ? 'Promo code copied.' : 'Link copied.',
        icon: 'img:statics/referral/invite-notify.svg',
        color: 'success',
        position: notifyPosition,
        timeout: 5000,
        classes: 'notificaton-box notificaton-box--referral'
      });
    }
  },
  // async created() {
  //   // await this.$store.dispatch('referral/getReferralPageData');
  // },
  mounted: function mounted() {
    if (this.$router.currentRoute.hash.indexOf("invite-code-box".concat(this.isDesktop ? '-desk' : '')) > -1) {
      this.$store.$ui.scrollToElement("invite-code-box".concat(this.isDesktop ? '-desk' : ''));
    }
  }
};