import "core-js/modules/es6.array.slice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ReferralTableItem from '@/components/Invite/ReferralTableItem';
export default {
  name: 'ReferralTable',
  components: {
    'app-referral-table-item': ReferralTableItem
  },
  props: {
    players: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      isShowAll: false
    };
  },
  computed: {
    playersTable: function playersTable() {
      return this.isShowAll ? this.players : this.players.slice(0, 5);
    },
    getShowTitle: function getShowTitle() {
      return this.isShowAll ? 'Hide' : 'Show all';
    }
  },
  methods: {
    showMore: function showMore() {
      this.isShowAll = !this.isShowAll;
    }
  }
};