//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Bannerinvite',
  props: {
    element: {
      type: String,
      default: ''
    },
    banner: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  methods: {
    scrollToCode: function scrollToCode(element) {
      var _this = this;

      this.$nextTick(function () {
        _this.$store.$ui.scrollToElement(element);
      });
    }
  }
};